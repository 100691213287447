import { CONTAINER_FILLING_WEDGEMAINTAIN } from "@kraftheinz/common";
import { n as normalizeComponent } from "./index.js";
import "lodash";
const CONTAINER_FILLING_WEDGEMAINTAIN_CONTAINER = "cf.wedgemaintain.container";
const CONTAINER_FILLING_WEDGEMAINTAIN_HOLD = "cf.wedgemaintain.hold";
const CREATE_PERMISSION = "create";
const DEL_ITEM_PERMISSION = "delete";
const TOGGLE_PERMISSION = "toggle";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", [_c("div", { staticClass: "title pb-3 pt-1" }, [_c("div", { staticClass: "d-flex" }, [_c("h2", [_vm._v("Wedge Maintenance")]), _c("div", { staticClass: "mr-auto" }), _c("a-button", { attrs: { "loading": _vm.loading, "type": "primary" }, on: { "click": function($event) {
    return _vm.loadData();
  } } }, [_vm._v("Refresh")])], 1)]), _c("div", { staticClass: "content" }, [_c("div", { staticClass: "top-content" }, [_c("a-row", { attrs: { "type": "flex" } }, [_c("a-col", { staticClass: "p-3 left", attrs: { "xs": 8 } }, [_c("h3", { staticClass: "mb-3" }, [_vm._v(" Current Sample "), _vm.loading ? _c("a-spin") : _vm._e()], 1), _c("div", { staticClass: "p-3 box" }, [_c("a-row", { attrs: { "type": "flex" } }, [_c("a-col", { attrs: { "xs": 12 } }, [_c("h6", [_vm._v("Sample Number")]), _c("p", { staticClass: "mb-3" }, [_vm._v(_vm._s(_vm.currentSampleData.sampleCode))])]), _c("a-col", { attrs: { "xs": 12 } }, [_c("h6", [_vm._v("Status")]), _vm.currentSampleData.status ? _c("tag-field", { key: "Status", staticClass: "mt-2", attrs: { "title": "Status", "value": _vm.currentSampleData.status, "color": _vm.currentSampleData.status && _vm.currentSampleData.status.toLowerCase() } }) : _vm._e()], 1)], 1), _c("hr"), _c("h6", { staticClass: "mt-3" }, [_vm._v("Item Code")]), _c("p", { staticClass: "mb-0" }, [_vm._v(_vm._s(_vm.currentSampleData.itemCode))])], 1)]), _c("a-col", { staticClass: "p-3 right", attrs: { "xs": 16 } }, [_c("h3", { staticClass: "mb-3" }, [_vm._v("Unproccessed Weights")]), _c("a-table", { attrs: { "loading": _vm.loading, "pagination": false, "row-key": "id", "columns": _vm.unproccessedWeightsColumns, "data-source": _vm.unproccessedWeightsData, "bordered": "" }, scopedSlots: _vm._u([{ key: "dateTimeFilled", fn: function(text, record) {
    return _c("div", {}, [_c("span", [_vm._v(_vm._s(_vm._f("moment")(record.dateTimeFilled, "DD/MM/YYYY hh:mm A")))])]);
  } }]) })], 1)], 1)], 1), _c("div", { staticClass: "bottom-content mt-5" }, [_c("a-row", [_c("a-col", { staticClass: "py-3 pl-3 left", attrs: { "xs": 11 } }, [_c("h3", { staticClass: "mb-3" }, [_vm._v("Weight Ready for Pallet")]), _c("a-table", { attrs: { "pagination": false, "row-key": "id", "loading": _vm.loading, "columns": _vm.weightReadyColumns, "data-source": _vm.weightReadyData, "bordered": "", "row-selection": _vm.rowSelection }, scopedSlots: _vm._u([{ key: "wedgeDateTime", fn: function(text, record) {
    return _c("div", {}, [_c("span", [_vm._v(_vm._s(_vm._f("moment")(record.wedgeDateTime, "DD/MM/YYYY hh:mm A")))])]);
  } }]) })], 1), _c("a-col", { staticClass: "center-buttons", attrs: { "xs": 2 } }, [_vm.$can(_vm.permissions.toggle, _vm.hold) ? _c("a-button", { attrs: { "type": "default", "icon": "right", "disabled": _vm.selectedWeightReady.length === 0 }, on: { "click": function($event) {
    return _vm.updateWeights(_vm.selectedWeightReady, "selectedWeightReady");
  } } }) : _vm._e(), _c("br"), _vm.$can(_vm.permissions.toggle, _vm.hold) ? _c("a-button", { staticClass: "mt-3", attrs: { "type": "default", "icon": "left", "disabled": _vm.selectedHeldWeight.length === 0 }, on: { "click": function($event) {
    return _vm.updateWeights(_vm.selectedHeldWeight, "selectedHeldWeight");
  } } }) : _vm._e()], 1), _c("a-col", { staticClass: "py-3 pr-3 right", attrs: { "xs": 11 } }, [_c("div", { staticClass: "d-flex" }, [_c("h3", { staticClass: "mb-3" }, [_vm._v("Held Weights")]), _c("div", { staticClass: "mr-auto" }), _vm.$can(_vm.permissions.create, _vm.container) ? _c("a-button", { attrs: { "loading": _vm.creating, "type": "link", "disabled": _vm.selectedHeldWeight.length === 0 }, on: { "click": function($event) {
    return _vm.createContainer();
  } } }, [_c("a-icon", { staticClass: "pl-2", staticStyle: { "font-size": "17px", "cursor": "pointer" }, attrs: { "type": "plus-circle" } })], 1) : _vm._e(), _vm.can(_vm.permissions.del) ? _c("a-button", { staticClass: "p-0", attrs: { "loading": _vm.creating, "type": "link", "disabled": _vm.selectedHeldWeight.length === 0 }, on: { "click": function($event) {
    return _vm.deleteWeights();
  } } }, [_c("a-icon", { staticStyle: { "font-size": "17px", "cursor": "pointer" }, attrs: { "type": "delete" } })], 1) : _vm._e()], 1), _c("a-table", { attrs: { "pagination": false, "loading": _vm.loading, "row-key": "id", "columns": _vm.heldWeightColumns, "data-source": _vm.heldWeightData, "bordered": "", "row-selection": _vm.rowSelection }, scopedSlots: _vm._u([{ key: "wedgeDateTime", fn: function(text, record) {
    return _c("div", {}, [_c("span", [_vm._v(_vm._s(_vm._f("moment")(record.wedgeDateTime, "DD/MM/YYYY hh:mm A")))])]);
  } }]) })], 1)], 1)], 1)])]);
};
var staticRenderFns$1 = [];
var WedgeMaintenance_vue_vue_type_style_index_0_scoped_true_lang = "";
const __vue2_script$1 = {
  name: "WedgeMaintenance",
  inject: ["resourceProps", "can"],
  data() {
    const [currentSampleResource, unproccessedWeightsResource] = this.resourceProps;
    const unproccessedWeightsColumns = [
      {
        title: "Head",
        dataIndex: "headCode"
      },
      {
        title: "Container Type",
        dataIndex: "containerTypeCode"
      },
      {
        title: "Weight (KG)",
        dataIndex: "weight"
      },
      {
        title: "Date & Time Filled",
        dataIndex: "dateTimeFilled",
        scopedSlots: { customRender: "dateTimeFilled" }
      }
    ];
    const weightReadyColumns = [
      {
        title: "Head",
        dataIndex: "headCode"
      },
      {
        title: "Container Type",
        dataIndex: "containerTypeCode"
      },
      {
        title: "Gross",
        dataIndex: "gross"
      },
      {
        title: "Tare",
        dataIndex: "tare"
      },
      {
        title: "Nett",
        dataIndex: "nett"
      },
      {
        title: "Date & Time Filled",
        dataIndex: "wedgeDateTime",
        scopedSlots: { customRender: "wedgeDateTime" }
      }
    ];
    const heldWeightColumns = weightReadyColumns.slice();
    const rowSelection = {
      onSelect: (record, selected, selectedRows) => {
        if (record.onHold) {
          this.selectedHeldWeight = selectedRows;
        } else {
          this.selectedWeightReady = selectedRows;
        }
      }
    };
    return {
      apiUrl: "#{VUE_APP_API_URL}#",
      currentSampleResource,
      unproccessedWeightsResource,
      currentSampleData: {},
      unproccessedWeightsData: [],
      weightReadyData: [],
      heldWeightData: [],
      selectedWeightReady: [],
      selectedHeldWeight: [],
      unproccessedWeightsColumns,
      weightReadyColumns,
      heldWeightColumns,
      loading: false,
      creating: false,
      rowSelection,
      hold: CONTAINER_FILLING_WEDGEMAINTAIN_HOLD,
      container: CONTAINER_FILLING_WEDGEMAINTAIN_CONTAINER,
      permissions: {
        toggle: TOGGLE_PERMISSION,
        create: CREATE_PERMISSION,
        del: DEL_ITEM_PERMISSION
      }
    };
  },
  mounted() {
    this.loadData();
  },
  methods: {
    loadData() {
      this.loading = true;
      this.fetchData().then(([
        currentSampleRes,
        unproccessedWeightsRes,
        weightReadyRes,
        heldWeightRes
      ]) => {
        this.currentSampleData = currentSampleRes.data;
        const unproccessedWeights = unproccessedWeightsRes.data && unproccessedWeightsRes.data.results;
        this.unproccessedWeightsData = this.handleSort(unproccessedWeights);
        const weightReadies = weightReadyRes.data && [
          ...weightReadyRes.data.results
        ];
        this.weightReadyData = this.handleSort(weightReadies);
        const heldWeights = heldWeightRes.data && [
          ...heldWeightRes.data.results
        ];
        this.heldWeightData = this.handleSort(heldWeights);
      }).finally(() => this.loading = false);
    },
    fetchData() {
      return Promise.all([
        this.currentSampleResource.crud.fetchEntity(),
        this.axios.get(`${this.unproccessedWeightsResource.apiUrl}/cf/wegde-stagings`),
        this.axios.get(`${this.unproccessedWeightsResource.apiUrl}/cf/wegdes?isHoldWeight=false&isProcessed=false`),
        this.axios.get(`${this.unproccessedWeightsResource.apiUrl}/cf/wegdes?isHoldWeight=true&isProcessed=false`)
      ]);
    },
    handleSort(dataSort) {
      return this._.orderBy(dataSort, ["headId", "wedgeDateTime"], ["asc", "desc"]);
    },
    submitUpdateWeight(item) {
      const url = `${this.unproccessedWeightsResource.apiUrl}/cf/wegdes/${item.id}`;
      const data = [
        {
          value: !item.onHold,
          path: "/OnHold",
          op: "replace"
        }
      ];
      return this.axios.patch(url, data);
    },
    deleteWeight(item) {
      const url = `${this.unproccessedWeightsResource.apiUrl}/cf/wegdes/${item.id}`;
      this.axios.delete(url).then(() => this.loadData()).catch((err) => this.showErrorMsg(err));
    },
    updateWeights(items, type) {
      Promise.all([...items.map((item) => this.submitUpdateWeight(item))]).then(() => {
        this.loadData();
        this[type] = [];
      });
    },
    createContainer() {
      const url = `${this.unproccessedWeightsResource.apiUrl}/cf/wegdes/container?prodLineId=${this.currentSampleData.prodLineId}&currSampleId=${this.currentSampleData.sampleId}&itemId=${this.currentSampleData.itemId}&prodLineLetter=${this.currentSampleData.prodLineLetter}&sampleAnalysisComplete=${this.currentSampleData.sampleAnalysisComplete}`;
      this.creating = true;
      this.axios.post(url, this.selectedHeldWeight).then(() => {
        this.loadData();
        this.$notification["success"]({
          message: "Successfully Created!"
        });
      }).catch((err) => this.showErrorMsg(err)).finally(() => this.creating = false);
    },
    showErrorMsg(err) {
      this.$notification["error"]({
        message: this._.get(err, "response.data.message") || "Errors encountered"
      });
    },
    deleteWeights() {
      this.selectedHeldWeight.forEach((item) => {
        this.deleteWeight(item);
      });
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, "0c35bca6", null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var WedgeMaintenance = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", [_c("bread-crumb", { attrs: { "items": _vm.itemsMenu } }), _c("resource", { attrs: { "name": ["cf.common.current-sample-status", "cf.wegde-stagings"], "api-url": _vm.apiUrl, "page": _vm.page, "resource-id-name": ["itemCode"] } }, [_c("wedge-maintenance")], 1)], 1);
};
var staticRenderFns = [];
const __vue2_script = {
  components: { WedgeMaintenance },
  data() {
    return {
      page: CONTAINER_FILLING_WEDGEMAINTAIN,
      apiUrl: "#{VUE_APP_API_URL}#",
      WedgeMaintenance,
      itemsMenu: [
        {
          key: "container-processing",
          title: "Container Processing",
          path: ""
        },
        {
          key: "wedge-maintenance",
          title: "Wedge Maintenance",
          path: "/container-filling/wedge-maintenance"
        }
      ]
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
